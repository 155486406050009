import { IcChecklist } from "../../../assets";
import { Button, Content, NavbarWithBack } from "../../../components";
import { UseServiceDetailContext } from "../../../contexts/service/detail";
import { elipseText, formatterCurrency } from "../../../utils";

const ServiceDetailPage = () => {
    const { data, serviceTier, serviceTierInclude, onChangeTabServiceTier, onCheckout } = UseServiceDetailContext();

    return (
        <>
            <NavbarWithBack title="Detail Layanan" />
            <Content navbar={false} footer={false} padding={false}>
                <div className="aspect-square bg-slate-400 overflow-hidden border border-violet-100">
                    <img src={data.service_file_model?.[0]?.path} alt={data.title} className="w-full h-full" />
                </div>
                <div className="px-3 pb-3">
                    <div className="flex gap-2 mt-3">
                        {data.service_file_model?.map((item, index) => {
                            return (
                                <div key={index} className="h-16 w-16 bg-slate-400 overflow-hidden rounded-md border border-violet-100">
                                    <img src={item.path} alt="ImageServices" className="w-full h-full" />
                                </div>
                            );
                        })}
                    </div>
                    <div className="mt-4">
                        <div className="flex flex-col">
                            <span className="font-bold text-[16px]">{data.title}</span>
                            <span>Status: Aktif</span>
                        </div>
                        <div className="flex gap-3 my-2">
                            <span className="font-bold text-[16px]">{formatterCurrency(serviceTier.price ?? 0)}</span>
                            <div className="rounded-full px-2 py-1 bg-green-100 text-green-700 font-bold">{data.tag_encode?.[0]}</div>
                        </div>
                        <div className="my-3">
                            <span className="font-medium">Pilih Level</span>
                            <div className="flex gap-2 mt-2">
                                {data.service_tier_model?.map((item, index) => {
                                    return (
                                        <Button key={index} className={`border ${item.active && 'bg-violet-500 text-white'}`} onClick={() => onChangeTabServiceTier(index)}>{item.title}</Button>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-[0.20rem] bg-slate-200"></div>
                <div className="px-3 py-3">
                    <div>
                        <span className="font-bold text-[16px]">Deskripsi Layanan</span>
                        <p>
                            {data.detail}
                        </p>
                    </div>
                    <div className="my-3">
                        <span className="font-bold text-[16px]">Apa Yang Termasuk</span>
                        <ul className="rounded-lg overflow-hidden border mt-2">
                            {serviceTierInclude.map((item, index) => {
                                return (
                                    <li key={index} className={`${index !== 0 && 'border-t'} p-2`}>
                                        <div className="flex gap-3">
                                            <IcChecklist width="16" height="16" className="text-green-700" />
                                            <span>{elipseText(item.title)}</span>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                {/* Button Fly */}
                <div className="w-full max-w-[31.25rem] shadow-all p-3 fixed bottom-0 bg-white">
                    <div className="flex justify-between gap-2">
                        <Button className="border-2 border-violet-500 flex gap-1 py-[0.4rem] grow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M8 9h8"></path>
                                <path d="M8 13h6"></path>
                                <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z"></path>
                            </svg>
                            Chat
                        </Button>
                        <Button className="bg-violet-500 text-white py-[0.5rem] grow" onClick={() => onCheckout()}>Lanjutkan</Button>
                    </div>
                </div>
            </Content>
        </>
    );
}

export default ServiceDetailPage;