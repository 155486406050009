import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { firebaseAuth } from "../../configs/firebase";
import { registerGooleRequest } from "../../request";
import { clearLocal, getLocalUser, setLocalUser } from "../../utils";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const [user, setUser] = useState(getLocalUser());

    const googleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(firebaseAuth, provider).then((result) => {
            setUser(result.user);
            // save to server
            registerGooleRequest({
                photo: result.user.photoURL,
                name: result.user.displayName,
                email: result.user.email,
            }).then((res) => {
                if (res) {
                    setLocalUser({ ...result.user, authFromServer: res });
                    setTimeout(() => {
                        navigation('/');
                        navigation(0);
                    }, 300);
                }
            });
        });
    }

    const googleSignOut = async () => {
        await signOut(firebaseAuth).then(() => {
            clearLocal();
            setUser(null);
            setTimeout(() => {
                navigation('/');
                navigation(0);
            }, 300);
        });
    }

    const getToken = () => {
        return `${user?.authFromServer?.data?.token_type} ${user?.authFromServer?.data?.token}`;
    }

    return (
        <AuthContext.Provider value={{ googleSignIn, googleSignOut, user, getToken }}>
            {children}
        </AuthContext.Provider>
    );
}

export const UseAuthContext = () => {
    return useContext(AuthContext);
}