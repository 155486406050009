import axios from "axios";
import { API_SERVICE_INCLUDE } from "../../configs/api";

export const showServiceIncludeRequest = async ({ id = '', key_item = '' }) => {
    try {
        const response = await axios.get(`${API_SERVICE_INCLUDE}?id=${id}&key_item=${key_item}`);
        return response.data;
    } catch (error) {
        return false;
    }
}