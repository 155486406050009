const AssetLogoGoogle = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.1712 7.36794H16.5V7.33335H9V10.6667H13.7096C13.0225 12.6071 11.1762 14 9 14C6.23875 14 4 11.7613 4 9.00002C4 6.23877 6.23875 4.00002 9 4.00002C10.2746 4.00002 11.4342 4.48085 12.3171 5.26627L14.6742 2.90919C13.1858 1.5221 11.195 0.666687 9 0.666687C4.39791 0.666687 0.666664 4.39794 0.666664 9.00002C0.666664 13.6021 4.39791 17.3334 9 17.3334C13.6021 17.3334 17.3333 13.6021 17.3333 9.00002C17.3333 8.44127 17.2758 7.89585 17.1712 7.36794Z" fill="#FBC02D" />
            <path d="M1.6275 5.12127L4.36542 7.12919C5.10625 5.29502 6.90042 4.00002 9 4.00002C10.2746 4.00002 11.4342 4.48085 12.3171 5.26627L14.6742 2.90919C13.1858 1.5221 11.195 0.666687 9 0.666687C5.79917 0.666687 3.02334 2.47377 1.6275 5.12127Z" fill="#E53935" />
            <path d="M9 17.3333C11.1525 17.3333 13.1083 16.5096 14.5871 15.17L12.0079 12.9875C11.1713 13.6212 10.1313 14 9 14C6.8325 14 4.99209 12.6179 4.29875 10.6891L1.58125 12.7829C2.96042 15.4816 5.76125 17.3333 9 17.3333Z" fill="#4CAF50" />
            <path d="M17.1712 7.3679L17.1646 7.33331H16.5H9V10.6666H13.7096C13.3796 11.5987 12.78 12.4025 12.0067 12.9879L12.0079 12.9871L14.5871 15.1696C14.4046 15.3354 17.3333 13.1666 17.3333 8.99998C17.3333 8.44123 17.2758 7.89581 17.1712 7.3679Z" fill="#1565C0" />
        </svg>
    );
}

export default AssetLogoGoogle;