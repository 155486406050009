import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { showCheckoutRequest, showPaymentMethodsRequest, storePaymentRequest } from "../../request";
import { getLocalToken } from "../../utils";

const CheckoutContext = createContext();

export const CheckoutContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const token = getLocalToken();
    const [checkout, setCheckout] = useState({});
    const [service, setService] = useState({});
    const [serviceTier, setServiceTier] = useState({});
    const [serviceInclude, setServiceInclude] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentActive, setPaymentActive] = useState({});

    const getCheckout = async () => {
        await showCheckoutRequest({ token: token.token }).then((res) => {
            setCheckout(res.data.checkout);
            setService(res.data.service);
            setServiceTier(res.data.service_tier);
            setServiceInclude(res.data.service_include);
        });
    }

    const getPaymentMethods = async () => {
        await showPaymentMethodsRequest().then((res) => {
            let data = settingActivePaymentMethod(res.data.payment_methods, 0, 0);
            setPaymentMethods(data);
        });
    }

    const settingActivePaymentMethod = (data, index, indexChild) => {
        data[index].data[indexChild].active = true;
        setPaymentActive(data[index].data[indexChild]);
        return data;
    }

    const onChangeTabPaymentMethods = (index, indexChild) => {
        paymentMethods.forEach((item) => {
            item.data.forEach((itemChild) => {
                itemChild.active = false;
            });
        })

        settingActivePaymentMethod(paymentMethods, index, indexChild);
    }

    const onPay = async () => {
        await storePaymentRequest({
            token: token.token, body: {
                checkout_id: checkout.id,
                type: paymentActive.type,
                code: paymentActive.code,
                amount: serviceTier.price,
            }
        }).then((res) => {
            if (res) {
                navigation('/payment', { state: { checkout_id: checkout.id } });
            } else {
                console.log('Gagal');
            }
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getCheckout();
        getPaymentMethods();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CheckoutContext.Provider value={{ service, serviceTier, serviceInclude, paymentMethods, paymentActive, onChangeTabPaymentMethods, onPay }}>
            {children}
        </CheckoutContext.Provider>
    );
}

export const UseCheckoutContext = () => {
    return useContext(CheckoutContext);
}