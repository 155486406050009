import { createContext, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getLocalUser } from "../../utils";

const ProfileContext = createContext();

export const ProfileContextProvider = ({ children }) => {
    const user = getLocalUser();
    const navigation = useNavigate();
    const paymentStatus = [
        {
            title: 'Menunggu Pembayaran',
            status: 'PENDING',
        },
        {
            title: 'Sedang Dikerjakan',
            status: 'PROGRESS'
        },
        {
            title: 'Selesai',
            status: 'SUCCESS'
        },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <ProfileContext.Provider value={{ user, navigation, paymentStatus }}>
            {children}
        </ProfileContext.Provider>
    );
}

export const UseProfileContext = () => {
    return useContext(ProfileContext);
}