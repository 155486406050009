export const setUpTimeRemaining = (date, callback) => {
    const targetDate = new Date(date);
    const timer = setInterval(() => {
        callback && callback(calculateTimeRemaining(targetDate));
    }, 1000);

    return () => clearInterval(timer);
}

const calculateTimeRemaining = (targetDate) => {
    const now = new Date();
    const timeDiff = targetDate - now;

    if (timeDiff <= 0) {
        // The target date has passed
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
}