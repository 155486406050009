import { AvAvatar1 } from "../../assets";
import { Button, Card, Content } from "../../components";
import { UseProfileContext } from "../../contexts/profile";

const ProfilePage = () => {
    const { user, navigation, paymentStatus } = UseProfileContext();

    const pagesNavigate = process.env.REACT_APP_PAGES;

    const onNavigate = (path) => {
        window.open(path, '_self');
    }

    return (
        <Content>
            <div className="mt-4">
                <div className="flex gap-4 items-center">
                    <div className="shadow-md min-w-[4rem] w-[4rem] max-w-[4rem] min-h-[4rem] h-[4rem] max-h-[4rem] rounded-full overflow-hidden p-1 border-2 border-violet-300">
                        {user.authFromServer.data.user.photo ? (<img src={user.authFromServer.data.user.photo} alt="FotoProfile" className="w-full rounded-full" />) : (<img src={AvAvatar1} alt="FotoProfile" className="w-full rounded-full" />)}
                    </div>
                    <div className="leading-4">
                        <span className="text-lg font-bold">{user.authFromServer.data.user.name ? user.authFromServer.data.user.name : 'Anonim'}</span>
                        <div>
                            <span className="col-span-5">{user.authFromServer.data.user.email}</span>
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <Button className="border py-[0.5rem]">Ubah Foto</Button>
                </div>
                <div className="mt-2 leading-3">
                    <span className="text-[0.60rem]">Ukuran file maksimal 5.000.000 bytes (5Mb) dengan jenis file (PNG, JPG, JPEG)</span>
                </div>
            </div>
            <Card className="grow mt-3 p-3">
                <h2 className="font-bold text-lg">Biodata</h2>
                <div className="mt-2 flex justify-between">
                    <div className="col-span-4"><span className="font-semibold">Jenis Kelamin</span></div>
                    {user.authFromServer.data.user.gender ? (<div className="col-span-2">{user.authFromServer.data.user.gender}</div>) : (<div className="col-span-5 text-violet-600 font-medium cursor-pointer" onClick={() => navigation('/setting/profile')}>Tambah Jenis Kelamin</div>)}
                </div>
                <div className="mt-2 flex justify-between">
                    <div><span className="font-semibold">Tanggal Lahir</span></div>
                    {user.authFromServer.data.user.date_of_birth ? (<div className="col-span-5">{user.authFromServer.data.user.date_of_birth}</div>) : (<div className="col-span-5 text-violet-600 font-medium cursor-pointer" onClick={() => navigation('/setting/profile')}>Tambah Tanggal Lahir</div>)}
                </div>
                {/*  */}
                <h2 className="font-bold text-lg mt-5">kontak</h2>
                <div className="mt-2 flex justify-between">
                    <div><span className="font-semibold">Nomor Hp</span></div>
                    {user.authFromServer.data.user.phone_number ? (<div className="col-span-5">{user.authFromServer.data.user.phone_number}</div>) : (<div className="col-span-5 text-violet-600 font-medium cursor-pointer" onClick={() => navigation('/setting/account')}>Tambah Nomor Hp</div>)}
                </div>
            </Card>
            <hr className="my-3" />
            <h2 className="font-bold text-[16px]">Pemesanan Layanan</h2>
            <div className="flex gap-3 mt-2 overflow-x-auto scrollbar-hidden">
                {paymentStatus.map((item, index) => {
                    return (
                        <Card key={index} className="cursor-pointer" onClick={() => navigation('/payment/list', { state: item })}>
                            <span className="whitespace-pre">{item.title}</span>
                        </Card>
                    );
                })}
            </div>
            <hr className="my-3" />
            <Card className="py-3 flex justify-between mt-2">
                <span className="font-semibold text-[13px]">Pengaturan</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-500" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M9 6l6 6l-6 6"></path>
                </svg>
            </Card>
            <Card className="py-3 flex justify-between mt-2" onClick={() => onNavigate(`${pagesNavigate}/about`)}>
                <span className="font-semibold text-[13px]">Tentang Pusproject</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-500" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M9 6l6 6l-6 6"></path>
                </svg>
            </Card>
            <Card className="py-3 flex justify-between mt-2" onClick={() => onNavigate(`${pagesNavigate}/intellectual-property-protection`)}>
                <span className="font-semibold text-[13px]">Hak Kekayaan Intelektual</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-500" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M9 6l6 6l-6 6"></path>
                </svg>
            </Card>
            <Card className="py-3 flex justify-between mt-2" onClick={() => onNavigate(`${pagesNavigate}/help-center`)}>
                <span className="font-semibold text-[13px]">Pusat Bantuan</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-500" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M9 6l6 6l-6 6"></path>
                </svg>
            </Card>
            <Card className="py-3 flex justify-between mt-2" onClick={() => onNavigate(`${pagesNavigate}/terms-and-conditions`)}>
                <span className="font-semibold text-[13px]">Syarat dan Ketentuan</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-500" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M9 6l6 6l-6 6"></path>
                </svg>
            </Card>
            <Card className="py-3 flex justify-between mt-2" onClick={() => onNavigate(`${pagesNavigate}/privacy-policy`)}>
                <span className="font-semibold text-[13px]">Kebijakan Privasi</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-500" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M9 6l6 6l-6 6"></path>
                </svg>
            </Card>
            <Card className="py-3 flex justify-between mt-2" onClick={() => onNavigate(`${pagesNavigate}/faq`)}>
                <span className="font-semibold text-[13px]">FAQ</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-500" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M9 6l6 6l-6 6"></path>
                </svg>
            </Card>
        </Content>
    );
}

export default ProfilePage;