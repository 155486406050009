import axios from "axios";
import { API_PAYMENT_DETAIL, API_PAYMENT_SHOW, API_PAYMENT_STORE } from "../../configs/api";

export const showPaymentRequest = async ({ token, status }) => {
    try {
        const response = await axios.get(`${API_PAYMENT_SHOW}?filter[status]=${status}`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        return response.data;
    } catch (error) {
        return false;
    }
}

export const detailPaymentRequest = async ({ token, checkout_id }) => {
    try {
        const response = await axios.get(`${API_PAYMENT_DETAIL}?checkout_id=${checkout_id}`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        return response.data;
    } catch (error) {
        return false;
    }
}

export const storePaymentRequest = async ({ token, body }) => {
    try {
        const response = await axios.post(API_PAYMENT_STORE, body, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        return response.data;
    } catch (error) {
        return false;
    }
}