import axios from "axios";
import { API_CHECKOUT_SHOW, API_CHECKOUT_STORE } from "../../configs/api";

export const showCheckoutRequest = async ({ token }) => {
    try {
        const response = await axios.get(API_CHECKOUT_SHOW, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        return response.data;
    } catch (error) {
        return false;
    }
}

export const storeCheckoutRequest = async ({ token, body }) => {
    try {
        const response = await axios.post(API_CHECKOUT_STORE, body, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        return response.data;
    } catch (error) {
        return false;
    }
}