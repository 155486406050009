import axios from "axios";
import { API_LOGIN, API_REGISTER, API_REGISTER_GOOGLE } from "../../configs/api";

export const loginRequest = async (body) => {
    try {
        const response = await axios.post(API_LOGIN, body);
        return response.data;
    } catch (error) {
        return false;
    }
}

export const registerRequest = async (body) => {
    try {
        const response = await axios.post(API_REGISTER, body);
        return response.data;
    } catch (error) {
        return false;
    }
}

export const registerGooleRequest = async (body) => {
    try {
        const response = await axios.post(API_REGISTER_GOOGLE, body);
        return response.data;
    } catch (error) {
        return false;
    }
}

export const logoutRequest = () => {
    console.log('Logout');
}