import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { showPaymentRequest } from "../../../request";
import { getLocalToken } from "../../../utils";

const PaymentListContext = createContext();

export const PaymentListContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const location = useLocation();
    const token = getLocalToken();
    const [payment, setPayment] = useState([]);

    const getPaymentList = async () => {
        await showPaymentRequest({ token: token.token, status: location.state.status }).then((res) => {
            console.log(res);
            setPayment(res.data.payment.data);
        });
    }

    useEffect(() => {
        getPaymentList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.state.status]);

    return (
        <PaymentListContext.Provider value={{ location, payment, navigation }}>
            {children}
        </PaymentListContext.Provider>
    );
}

export const UsePaymentListContext = () => {
    return useContext(PaymentListContext);
}