import { AssetWaLogo, IlDoubleChat } from "../../assets";
import { Content, NavbarWithBack } from "../../components";
import { sendWaMessage } from "../../utils";

const ChatContactPage = () => {
    return (
        <>
            <NavbarWithBack title="Chat" />
            <Content navbar={false} footer={false}>
                <div className="mt-3">
                    <div className="py-3 border-b flex gap-2 items-center" onClick={() => sendWaMessage()}>
                        <div className="min-w-[2.7rem] w-[2.7rem] max-w-[2.7rem] min-h-[2.7rem] h-[2.7rem] max-h-[2.7rem] rounded-full overflow-hidden p-1 border border-violet-300">
                            <img src={AssetWaLogo} alt="FotoProfile" className="w-full rounded-full" />
                        </div>
                        <div className="grow">
                            <div className="flex gap-2 items-center">
                                <span className="font-semibold text-sm">Admin Pusproject</span>
                                <div className="rounded-full bg-green-100 text-green-500 font-bold px-2 text-[10px] text-center">Aktif</div>
                            </div>
                            <div className="flex justify-between items-center">
                                <span>Hallo kak, bisa kami bantu</span>
                                <div className="rounded-full bg-red-100 text-red-500 font-bold px-2 py-[0.15rem] text-[10px] text-center">1</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="h-[60vh] flex justify-center items-center">
                    <div className="text-center">
                        <div className="flex justify-center">
                            <img src={IlDoubleChat} alt="IllustrationDoubleChat" className="w-56" />
                        </div>
                        <span className="font-bold">Chatting, Sedang dalam pengembangan</span><br />
                        <div className="leading-4">
                            <small>Kami akan hadir dan mempermudah dalam konsultasi, project sedang dikerjakan oleh tim developer kami, bisa hubungi admin kami terlebih dahulu malalui nomor </small><small className="font-semibold">+62 821-1490-9358</small>
                        </div>
                    </div>
                </div>
            </Content>
        </>
    );
}

export default ChatContactPage;