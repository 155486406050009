import { createContext, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { AssetIcAllMenus, AssetIcCoorperation, AssetIcProduct } from "../../assets";
import { showServiceCategoryRequest, showServiceRequest } from "../../request";
import { getLocalUser } from "../../utils";

const MainContext = createContext();

export const MainContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const user = getLocalUser();
    const [service, setService] = useState([]);
    const [popular, setPopular] = useState([]);
    const [list, setList] = useState([]);
    const [categorys, setCategorys] = useState([
        {
            title: 'Semua',
            title_key: '',
            active: true,
        }
    ]);
    const menus = [
        {
            icon: AssetIcAllMenus,
            title: 'Semua',
        },
        {
            icon: AssetIcProduct,
            title: 'Produk',
        },
        {
            icon: AssetIcCoorperation,
            title: 'Bisnis',
        },
    ];

    const getPopular = async () => {
        await showServiceRequest({ perpage: 5 }).then((res) => {
            setPopular(res.data.service.data);
        });
    }

    const getCategory = async () => {
        await showServiceCategoryRequest({}).then((res) => {
            setUpCategory(res.data);
        });
    }

    const getService = async (categoryTitle = '') => {
        await showServiceRequest({ category: categoryTitle }).then((res) => {
            setList(res.data.service.data);
        });
    }

    const setUpCategory = (data) => {
        let serviceData = data.category.data;
        serviceData[0].active = true;
        let categoryData = data.category.data[0].child_encode;
        categoryData[0].active = true;
        let categorysData = [...categorys, ...data.categoryFirst.child_encode];

        setService(serviceData);
        setCategorys(categorysData);
    }

    const onChangeTabCategorys = (index) => {
        categorys.forEach((item) => {
            item.active = false;
        });

        categorys[index].active = true;
        getService(categorys[index].title_key);
        setCategorys([...categorys]);
    }

    useEffect(() => {
        getPopular();
        getCategory();
        getService();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MainContext.Provider value={{ navigation, user, menus, service, categorys, popular, list, onChangeTabCategorys }}>
            {children}
        </MainContext.Provider>
    );
}

export const UseMainContext = () => {
    return useContext(MainContext);
}