import { Route, Routes } from 'react-router-dom';
import { ProtectedAuth } from './components';
import { AuthContextProvider } from './contexts/auth';
import { LoginContextProvider } from './contexts/auth/LoginContext';
import { RegisterContextProvider } from './contexts/auth/RegisterContext';
import { CheckoutContextProvider } from './contexts/checkout';
import { MainContextProvider } from './contexts/main';
import { PaymentContextProvider } from './contexts/payment';
import { PaymentListContextProvider } from './contexts/payment/list';
import { ProfileContextProvider } from './contexts/profile';
import { ServiceContextProvider } from './contexts/service';
import { ServiceDetailContextProvider } from './contexts/service/detail';
import { ChatContactPage, CheckoutPage, LoginPage, MainApp, PaymentListPage, PaymentPage, ProfilePage, RegisterPage, ServiceDetailPage, ServicePage } from './pages';

function App() {
  return (
    <AuthContextProvider>
      <Routes>
        <Route path="/" element={
          <MainContextProvider>
            <MainApp />
          </MainContextProvider>
        } />
        {/* Auth */}
        <Route path="/login" element={
          <LoginContextProvider>
            <LoginPage />
          </LoginContextProvider>
        } />
        <Route path="/register" element={
          <RegisterContextProvider>
            <RegisterPage />
          </RegisterContextProvider>
        } />
        {/* Profile */}
        <Route path="/profile" element={
          <ProtectedAuth>
            <ProfileContextProvider>
              <ProfilePage />
            </ProfileContextProvider>
          </ProtectedAuth>
        } />
        {/* Chat */}
        <Route path="/chat-contact" element={
          <ChatContactPage />
        } />
        {/* Service */}
        <Route path="/service" element={
          <ServiceContextProvider>
            <ServicePage />
          </ServiceContextProvider>
        } />
        <Route path="/service/detail/:id/:title" element={
          <ServiceDetailContextProvider>
            <ServiceDetailPage />
          </ServiceDetailContextProvider>
        } />
        {/* Checkout */}
        <Route path="/checkout" element={
          <ProtectedAuth>
            <CheckoutContextProvider>
              <CheckoutPage />
            </CheckoutContextProvider>
          </ProtectedAuth>
        } />
        {/* payment */}
        <Route path="/payment" element={
          <ProtectedAuth>
            <PaymentContextProvider>
              <PaymentPage />
            </PaymentContextProvider>
          </ProtectedAuth>
        } />
        <Route exact path="/payment/list" element={
          <ProtectedAuth>
            <PaymentListContextProvider>
              <PaymentListPage />
            </PaymentListContextProvider>
          </ProtectedAuth>
        } />
      </Routes>
    </AuthContextProvider>
  );
}

export default App;
