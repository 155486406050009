import { IcChecklist } from "../../assets";
import { AlertSecurity, Button, Content, NavbarWithBack } from "../../components";
import { UseCheckoutContext } from "../../contexts/checkout";
import { elipseText, formatterCurrency } from "../../utils";

const CheckoutPage = () => {
    const { service, serviceTier, serviceInclude, paymentMethods, paymentActive, onChangeTabPaymentMethods, onPay } = UseCheckoutContext();

    return (
        <>
            <NavbarWithBack title="Pembayaran" />
            <Content padding={false} navbar={false} footer={false}>
                <div className="px-3 pt-3 pb-5">
                    <div className="my-2">
                        <AlertSecurity />
                    </div>
                    <div className="mt-3">
                        <h2 className="text-[16px] font-bold">Detail Layanan</h2>
                        <div className="flex gap-3 mt-4">
                            <div className="bg-slate-400 rounded-md h-20 min-w-[5rem] w-20 max-w-[5rem] border overflow-hidden">
                                <img src={service.service_file_model?.[0]?.path} alt="ImageServices" className="w-full h-full" />
                            </div>
                            <div>
                                <h4 className="font-medium text-[14px]">{service.title}</h4>
                                <div className="mt-2 text-[11px] flex gap-2">
                                    {service.tag_encode?.map((item, index) => {
                                        return (
                                            <div key={index} className="rounded-full px-3 py-1 bg-green-100 text-green-700 font-bold">{item}</div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-3" />
                    <div className="mt-2">
                        <h2 className="font-bold text-[16px]">Layanan</h2>
                        <ul className="mt-2">
                            {serviceInclude.map((item, index) => {
                                return (
                                    <li key={index} className={`p-1`}>
                                        <div className="flex gap-2">
                                            <IcChecklist width="16" height="16" className="text-green-700" />
                                            <span>{elipseText(item.title)}</span>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="mt-5">
                        <h3 className="font-bold text-[16px]">Metode Pembayaran</h3>
                        <div className="my-3 border border-violet-300 py-4 px-7 rounded-lg flex gap-10">
                            <div className="grow">
                                <img src={paymentActive.path_full} alt={paymentActive.name} className="h-6" />
                                <div className="mt-2">
                                    <span className="font-semibold text-md">{paymentActive.type_display}</span>
                                </div>
                            </div>
                            <div className="flex items-center justify-end">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-violet-400" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                    <path d="M9 12l2 2l4 -4"></path>
                                </svg>
                            </div>
                        </div>
                        <div className="border rounded-xl p-4">
                            {paymentMethods.map((item, index) => {
                                return (
                                    <div key={index} className="mt-3">
                                        <span className="font-semibold text-md">{item.title}</span>
                                        <div className="flex flex-wrap gap-3 mt-2">
                                            {item.data.map((itemChild, indexChild) => {
                                                return (
                                                    <div key={indexChild} className={`py-3 px-4 rounded-xl cursor-pointer bg-slate-100 relative ${itemChild.active ? 'border border-green-500' : 'border border-white'}`} onClick={() => onChangeTabPaymentMethods(index, indexChild)}>
                                                        {itemChild.active && (
                                                            <div className="absolute -top-2 -right-2 bg-white p-[2px] rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-green-500" width="19" height="19" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                    <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" strokeWidth="0" fill="currentColor"></path>
                                                                </svg>
                                                            </div>
                                                        )}
                                                        <img src={itemChild.path_full} alt={itemChild.name} className="h-6" />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                {/* Button Fly */}
                <div className="w-full max-w-[31.25rem] shadow-all p-3 fixed bottom-0 bg-white">
                    <div className="flex justify-between items-center gap-2">
                        <div className="grow flex items-center gap-2">
                            <span className="text-[1rem]">Total : </span>
                            <span className="font-bold text-[1rem]">{formatterCurrency(serviceTier.price ?? 0)}</span>
                        </div>
                        <Button className="bg-violet-500 text-white py-[0.5rem]" onClick={() => onPay()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M13.018 20.687c-.333 .119 -.673 .223 -1.018 .313a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3a12 12 0 0 0 8.5 3c.433 1.472 .575 2.998 .436 4.495"></path>
                                <path d="M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5"></path>
                                <path d="M19 21v1m0 -8v1"></path>
                            </svg>
                            Bayar
                        </Button>
                    </div>
                </div>
            </Content>
        </>
    );
}

export default CheckoutPage;