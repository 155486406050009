import { AssetBanner3, AssetIcDigitalService, AssetLogo } from "../../assets";
import { Button, Card, Content } from "../../components";
import { UseMainContext } from "../../contexts/main";
import { elipseText, formatterCurrency } from "../../utils";

const MainApp = () => {
    const { navigation, user, menus, service, categorys, popular, list, onChangeTabCategorys } = UseMainContext();

    return (
        <Content>
            {!user?.authFromServer ? (
                <div className="flex justify-between gap-5 items-center py-4">
                    <div>
                        <div className="w-24">
                            <AssetLogo />
                        </div>
                        <span>Buka fitur lengkap dengan akun</span>
                    </div>
                    <div>
                        <Button className="bg-violet-500 text-white">Masuk</Button>
                    </div>
                </div>
            ) : <div className="mt-4" />}
            <div className="rounded-lg overflow-hidden bg-violet-400">
                <img src={AssetBanner3} alt="BannerPromo" />
            </div>
            <div className="my-7">
                <div className="flex gap-4 overflow-x-auto scrollbar-hidden">
                    {menus.map((item, index) => {
                        return (
                            <div className="min-w-[4rem] w-16 max-w-[4rem]" key={index}>
                                <div className="h-16 rounded-xl">
                                    <img src={item.icon} alt={item.title} />
                                </div>
                                <div className="text-center mt-3">
                                    <span className="font-medium">{item.title}</span>
                                </div>
                                {index !== 0 && (<div className="rounded-full bg-yellow-100 text-yellow-500 font-bold py-1 px-2 text-[7px] text-center">Segera Hadir</div>)}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="my-4">
                <h2 className="font-bold text-[14px]">Layanan Terbaru</h2>
                <div className="flex overflow-x-auto scrollbar-hidden mt-2 gap-2">
                    {popular.map((item, index) => {
                        return (
                            <Card key={index} className="cursor-pointer" onClick={() => navigation(`/service/detail/${item.id}/${encodeURIComponent(item.title)}`)}>
                                <div className="w-[9.11rem] h-36 rounded-xl bg-slate-400 overflow-hidden">
                                    <img src={item.service_file_model[0].path} alt={item.title} />
                                </div>
                                <div className="mt-2">
                                    <span>{elipseText(item.title, 30)}</span>
                                </div>
                                <div className="mt-1">
                                    <span className="font-bold">{formatterCurrency(item.service_tier_model[0].price)}</span>
                                </div>
                                <div className="mt-1 text-[10px] flex gap-2">
                                    {item.tag_encode.map((cItem, cIndex) => {
                                        return (
                                            <div key={cIndex} className="rounded-full px-2 py-1 bg-green-100 text-green-700 font-bold">{cItem}</div>
                                        );
                                    })}
                                </div>
                            </Card>
                        );
                    })}
                </div>
            </div>
            <div className="my-7">
                <h2 className="font-bold text-[14px]">Kategori Layanan</h2>
                <div className="mt-4 overflow-x-auto scrollbar-hidden">
                    <div className="flex gap-2">
                        {service.map((item, index) => {
                            return (
                                <Card className="p-0 overflow-hidden min-w-[8rem] w-32 max-w-[8rem]" key={index} onClick={() => navigation('/service')}>
                                    <div className="flex justify-center bg-violet-200 py-6 px-3 rounded-lg">
                                        <div className="min-w-[5rem] w-20 max-w-[5rem] h-20">
                                            <img src={AssetIcDigitalService} alt="BannerPromo" />
                                        </div>
                                    </div>
                                    <div className="text-center leading-4 px-3 py-3">
                                        <span className="font-semibold">{item.category}</span>
                                    </div>
                                </Card>
                            );
                        })}
                    </div>
                </div>
            </div>
            <hr />
            {/* All Service */}
            <div className="mt-3">
                <div className="leading-4">
                    <h2 className="font-bold text-[14px]">Layanan</h2>
                    <span>Aman dan terpercaya, layanan yang tersedia di pusproject</span>
                </div>
                <div className="flex gap-2 overflow-x-auto scrollbar-hidden pt-4 pb-2">
                    {categorys.map((item, index) => {
                        return (
                            <Button className={`border ${item.active ? 'bg-violet-500 text-white' : 'hover:bg-slate-50'}`} key={index} onClick={() => onChangeTabCategorys(index)}>{item.title}</Button>
                        );
                    })}
                </div>
                <div className="grid grid-cols-2 mt-3 gap-2">
                    {list.map((item, index) => {
                        return (
                            <Card key={index} className="cursor-pointer" onClick={() => navigation(`/service/detail/${item.id}/${encodeURIComponent(item.title)}`)}>
                                <div className="aspect-square rounded-xl bg-slate-400 overflow-hidden">
                                    <img src={item.service_file_model[0].path} alt={item.title} />
                                </div>
                                <div className="mt-2">
                                    <span>{elipseText(item.title, 35)}</span>
                                </div>
                                <div className="mt-1">
                                    <span className="font-bold">{formatterCurrency(item.service_tier_model[0].price)}</span>
                                </div>
                                <div className="mt-1 text-[10px] flex gap-2">
                                    {item.tag_encode.map((cItem, cIndex) => {
                                        return (
                                            <div key={cIndex} className="rounded-full px-2 py-1 bg-green-100 text-green-700 font-bold">{cItem}</div>
                                        );
                                    })}
                                </div>
                            </Card>
                        );
                    })}
                </div>
            </div>
        </Content>
    );
}

export default MainApp;