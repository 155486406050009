import { Card, Content, NavbarWithBack } from "../../components";
import { UsePaymentContext } from "../../contexts/payment";
import { dateFormatter, formatterCurrency, setZero, validateHandleText } from "../../utils";

const PaymentPage = () => {
    const { payment, payment_methods, timeRemaining, onCopy } = UsePaymentContext();

    return (
        <>
            <NavbarWithBack title="Pembayaran" />
            <Content navbar={false} footer={false}>
                <div className="text-center mt-10">
                    <h3 className="font-semibold text-lg">Selesaikan Pembayaran Dalam</h3>
                    <h2 className="font-semibold text-2xl text-orange-600">{validateHandleText(timeRemaining.hours, setZero(timeRemaining.hours))}:{validateHandleText(timeRemaining.minutes, setZero(timeRemaining.minutes))}:{validateHandleText(timeRemaining.seconds, setZero(timeRemaining.seconds))}</h2>
                    <h3 className="mt-3 font-medium text-[0.8rem] text-slate-500">Batas Akhir Pembayaran</h3>
                    <h3 className="font-semibold text-lg">{validateHandleText(payment.expiration_date, dateFormatter(payment.expiration_date))}</h3>
                </div>
                <Card className="mt-10 p-[1.5rem]">
                    <div className="flex justify-between items-center border-b pb-2">
                        <span className="font-semibold text-[0.8rem]">{validateHandleText(payment_methods.name)}, {validateHandleText(payment_methods.type_display)}</span>
                        <div className="w-14">
                            <img src={payment_methods.path_full} alt={payment_methods.name} />
                        </div>
                    </div>
                    <div className="mt-4">
                        <span className="font-medium text-[0.8rem] text-slate-500">Nomor {validateHandleText(payment_methods.type)}</span>
                        <div className="flex justify-between items-center">
                            <span className="font-semibold text-[1rem]">{validateHandleText(payment.transfer_code)}</span>
                            <br />
                            <span className="font-semibold text-md flex items-center text-violet-500 cursor-pointer" onClick={() => onCopy()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"></path>
                                    <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path>
                                </svg>
                                Salin
                            </span>
                        </div>
                    </div>
                    <div className="mt-4">
                        <span className="font-medium text-[0.8rem] text-slate-500">Total Pembayaran</span>
                        <div className="flex justify-between items-center">
                            <span className="font-semibold text-[0.9rem]">{validateHandleText(payment.amount, formatterCurrency(payment.amount))}</span>
                        </div>
                    </div>
                </Card>
            </Content>
        </>
    );
}

export default PaymentPage;