import axios from "axios";
import { API_SERVICE_CATEGORY } from "../../configs/api";

export const showServiceCategoryRequest = async ({ key = '' }) => {
    try {
        const response = await axios.get(`${API_SERVICE_CATEGORY}?filter[key]=${key}`);
        return response.data;
    } catch (error) {
        return false;
    }
}