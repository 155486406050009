import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { showServiceCategoryRequest, showServiceRequest } from "../../request";

const ServiceContext = createContext();

export const ServiceContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const [list, setList] = useState([]);
    const [category, setCategory] = useState([
        {
            title: 'Semua',
            title_key: '',
            active: true,
        }
    ]);

    const getCategory = async () => {
        await showServiceCategoryRequest({ key: 1 }).then((res) => {
            setCategory([...category, ...res.data.category.data[0].child_encode]);
        });
    }

    const getService = async (categoryTitle) => {
        await showServiceRequest({ category: categoryTitle }).then((res) => {
            setList(res.data.service.data);
        });
    }

    const onChangeTabCategory = (index) => {
        category.forEach((item) => {
            item.active = false;
        });

        category[index].active = true;
        getService(category[index].title_key);
        setCategory([...category]);
    }

    useEffect(() => {
        getCategory();
        getService();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ServiceContext.Provider value={{ navigation, category, list, onChangeTabCategory }}>
            {children}
        </ServiceContext.Provider>
    );
}

export const UseServiceContext = () => {
    return useContext(ServiceContext);
}