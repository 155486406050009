import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { detailServiceRequest, showServiceIncludeRequest, storeCheckoutRequest } from "../../../request";
import { getLocalToken } from "../../../utils";

const ServiceDetailContext = createContext();

export const ServiceDetailContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const params = useParams();
    const token = getLocalToken();
    const [data, setData] = useState({});
    const [serviceTier, setServiceTier] = useState({});
    const [serviceTierInclude, setServiceTierInclude] = useState([]);

    const getService = async () => {
        await detailServiceRequest(params.id).then((res) => {
            res.data.service.service_tier_model[0].active = true;

            setServiceTier(res.data.service.service_tier_model[0]);
            setData(res.data.service);

            // Get include
            getInclude(res.data.service.service_tier_model[0].key_item);
        });
    }

    const getInclude = async (key_item) => {
        await showServiceIncludeRequest({ id: params.id, key_item: key_item }).then((res) => {
            setServiceTierInclude(res.data.include);
        });
    }

    const onChangeTabServiceTier = (index) => {
        data.service_tier_model.forEach((item) => {
            item.active = false;
        });

        data.service_tier_model[index].active = true;
        setServiceTier(data.service_tier_model[index]);
        setData({ ...data });
        // Get include
        getInclude(data.service_tier_model[index].key_item);
    }

    const onCheckout = async () => {
        await storeCheckoutRequest({
            token: token.token,
            body: {
                type: 'service',
                attribute: {
                    service_id: data.id,
                    key_item: serviceTier.key_item,
                },
            },
        }).then((res) => {
            navigation('/checkout');
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getService();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ServiceDetailContext.Provider value={{ navigation, data, serviceTier, serviceTierInclude, onChangeTabServiceTier, onCheckout }}>
            {children}
        </ServiceDetailContext.Provider>
    );
}

export const UseServiceDetailContext = () => {
    return useContext(ServiceDetailContext);
}