import { IcShieldLock } from "../../../assets";

const AlertSecurity = () => {
    return (
        <div className="bg-blue-100 px-3 py-3 rounded-xl">
            <div className="flex gap-2 font-semibold">
                <IcShieldLock />
                <div>
                    <span className="text-blue-800">Perlindungan,</span>
                    <span>Uang akan dikembalikan 100%, jika anda tidak puas.</span>
                </div>
            </div>
        </div>
    );
}

export default AlertSecurity;