import axios from "axios";
import { API_SERVICE_DETAIL, API_SERVICE_SHOW } from "../../configs/api";

export const showServiceRequest = async ({ perpage = 10, category = '' }) => {
    try {
        const response = await axios.get(`${API_SERVICE_SHOW}?perpage=${perpage}&category=${category}`);
        return response.data;
    } catch (error) {
        return false;
    }
}

export const detailServiceRequest = async (id) => {
    try {
        const response = await axios.get(`${API_SERVICE_DETAIL}?id=${id}`);
        return response.data;
    } catch (error) {
        return false;
    }
}