import axios from "axios";
import { API_PAYMENT_METHODS } from "../../configs/api";

export const showPaymentMethodsRequest = async () => {
    try {
        const response = await axios.get(API_PAYMENT_METHODS);
        return response.data;
    } catch (error) {
        return false;
    }
}