import { createContext, useContext, useState } from "react"
import { useNavigate } from "react-router-dom";
import { UseAuthContext } from ".";
import { registerRequest } from "../../request";
import { setLocalUser } from "../../utils";

const RegisterContext = createContext();

export const RegisterContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const { googleSignIn } = UseAuthContext();
    const [controller, setController] = useState();
    const [loader, setLoader] = useState({});

    const handleGoogleSignIn = async () => {
        try {
            await googleSignIn();
        } catch (error) {
            console.log(error);
        }
    }

    const onSetController = (field, value) => {
        setController({ ...controller, [field]: value });
    }

    const onSetLoader = (field, value) => {
        setLoader({ ...loader, [field]: value });
    }

    const onSaved = async () => {
        onSetLoader('register', true);
        await registerRequest({ ...controller }).then((res) => {
            onSetLoader('register', false);
            if (res) {
                setLocalUser({ authFromServer: res });
                setTimeout(() => {
                    navigation('/');
                    navigation(0);
                }, 300);
            }
        });
    }

    return (
        <RegisterContext.Provider value={{ navigation, loader, handleGoogleSignIn, onSetController, onSaved }}>
            {children}
        </RegisterContext.Provider>
    );
}

export const UseRegisterContext = () => {
    return useContext(RegisterContext);
}