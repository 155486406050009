import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { detailPaymentRequest } from "../../request";
import { copyClipboard, getLocalToken, setUpTimeRemaining } from "../../utils";

const PaymentContext = createContext();

export const PaymentContextProvider = ({ children }) => {
    const token = getLocalToken();
    const location = useLocation();
    const [payment, setPayment] = useState({});
    const [payment_methods, setPaymentMethods] = useState({});
    const [timeRemaining, setTimeRemaining] = useState({});

    const getPayment = async () => {
        await detailPaymentRequest({ token: token.token, checkout_id: location.state.checkout_id }).then((res) => {
            setPayment(res.data.payment);
            setPaymentMethods(res.data.payment_methods);
            setUpTimeRemaining(res.data.payment.expiration_date, (res) => {
                setTimeRemaining(res);
            });
        });
    }

    const onCopy = async () => {
        await copyClipboard(payment.transfer_code).then((res) => {
            console.log(res);
        });
    }

    useEffect(() => {
        getPayment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PaymentContext.Provider value={{ payment, payment_methods, timeRemaining, onCopy }}>
            {children}
        </PaymentContext.Provider>
    );
}

export const UsePaymentContext = () => {
    return useContext(PaymentContext);
}