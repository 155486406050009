import { AlertSecurity, Card, Content, NavbarWithBack } from "../../components";
import { UseServiceContext } from "../../contexts/service";
import { elipseText, formatterCurrency } from "../../utils";

const ServicePage = () => {
    const { navigation, category, list, onChangeTabCategory } = UseServiceContext();

    return (
        <>
            <NavbarWithBack title="Layanan" />
            <Content navbar={false} footer={false}>
                <div className="mt-5">
                    <AlertSecurity />
                </div>
                <div className="mt-5">
                    <h1 className="font-bold text-[14px]">Layanan</h1>
                    <span>Aman dan terpercaya, telah diverifikasi oleh pusproject</span>
                </div>
                <div className="flex gap-2 py-2 mt-2 sticky top-0 bg-white overflow-x-auto scrollbar-hidden">
                    {category.map((item, index) => {
                        return (
                            <div key={index} className={`rounded-lg border py-2 px-3 cursor-pointer ${item.active ? 'bg-violet-500 text-white' : 'hover:bg-slate-50'}`} onClick={() => onChangeTabCategory(index)}>
                                <span className="font-medium whitespace-pre">{item.title}</span>
                            </div>
                        );
                    })}
                </div>
                <div className="mt-3">
                    <div className="grid grid-cols-2 mt-3 gap-2">
                        {list.map((item, index) => {
                            return (
                                <Card key={index} className="cursor-pointer" onClick={() => navigation(`/service/detail/${item.id}/${encodeURIComponent(item.title)}`)}>
                                    <div className="aspect-square rounded-xl bg-slate-400 overflow-hidden">
                                        <img src={item.service_file_model[0].path} alt={item.title} />
                                    </div>
                                    <div className="mt-2">
                                        <span>{elipseText(item.title, 35)}</span>
                                    </div>
                                    <div className="mt-1">
                                        <span className="font-bold">{formatterCurrency(item.service_tier_model[0].price)}</span>
                                    </div>
                                    <div className="mt-1 text-[10px] flex gap-2">
                                        {item.tag_encode.map((cItem, cIndex) => {
                                            return (
                                                <div key={cIndex} className="rounded-full px-2 py-1 bg-green-100 text-green-700 font-bold">{cItem}</div>
                                            );
                                        })}
                                    </div>
                                </Card>
                            );
                        })}
                    </div>
                </div>
            </Content>
        </>
    );
}

export default ServicePage;